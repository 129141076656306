<template lang="pug">
  .checklist-items
    //- p( v-for="(checkList, index) in items" :key="index") {{ checkList}}
    checklist-item(
      v-for="(checkList, index) in items" :key="index"
      :item="checkList"
      @change:is_done="update('status', checkList.id, $event)"
      @change:name="update('name', checkList.id, $event)"
      @change:comment="update('comment', checkList.id, $event)"
      @remove:item="$emit('remove:item', checkList)"
    )
    //- .checklist-item(v-for="(checkList, index) in items" :key="index")

    //-   app-checkbox(
    //-     :id="checkList.id"
    //-     :inputValue="checkList.is_done"
    //-     :value="checkList.is_done"
    //-     @change="update('status', checkList.id, $event)"
    //-   ).checklist-checkbox

    //-   crm-textarea(
    //-     :value="checkList.name"
    //-     :maxlength="3000"
    //-     :rows="1"
    //-     :placeholder="'Type checkbox name here'"
    //-     @change="update('name', checkList.id, $event)"
    //-     :disabled="editable"
    //-   ).checkList-textarea

    //-   ico-message(class="mr-2" @click.native="toggleEditable")
    //-   app-button(@click.native="$emit('remove:item', checkList)" icon iconSmall rounded).unselectable
    //-     <i class="material-icons">more_vert</i>
</template>

<script>

export default {
  props: {
    items: Array
  },

  data: () => ({
  }),

  components: {
    icoMessage: () => import("@/assets/img/ui/components/IcoMessage.vue"),
    checklistItem: () => import("./CourseChecklistItem.vue"),

  },

  methods: {
    update(params, checkListID, data) {
      this.$emit(`change:${params}`, checkListID, data)
    }
  }
}
</script>

<style lang="scss">


</style>
